import PropTypes from 'prop-types';
// @mui
import {
    Tooltip,
} from '@mui/material';

import Iconify from '../../../components/iconify';
import { IconButtonAnimate } from '../../../components/animate';


// ----------------------------------------------------------------------
function batteryIcon(val = "1") {
    if (val >= 6) {
        return <Iconify icon="mdi:battery" />;
    }
    if (val >= 5.5) {
        return <Iconify icon="mdi:battery-50" />;
    }
    if (val >= 5) {
        return <Iconify icon="mdi:battery-10" />;
    }
    return <Iconify icon="mdi:battery-alert-variant-outline" />;
}

BatteryStatus.propTypes = {
    battery: PropTypes.number
}
export default function BatteryStatus({ battery }) {
    return (
      <IconButtonAnimate color="default" sx={{ width: 40, height: 40 }}>
        <Tooltip title={battery ? `${battery} Volts` : 'No battery exists'}>
          {batteryIcon(battery)}
        </Tooltip>
      </IconButtonAnimate>
    );
  }

// ----------------------------------------------------------------------



