import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo, useRef } from 'react';
import { io } from 'socket.io-client';
// utils
import axios from '../utils/axios';
import localStorageAvailable from '../utils/localStorageAvailable';
//
import { isValidToken, setSession } from './utils';

import { useDispatch } from '../redux/store';
import { addSocketId, getUserMessages } from '../redux/slices/chat';
// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true, // Originally true
      user: action.payload.user,
    };
  }
  if (action.type === 'VERIFYEMAILCODE') {
    return {
      ...state,
      isAuthenticated: true, // Originally true
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      isAuthenticated: false, // Originally true, This change helps the flow
      user: action.payload.user,
    };
  }

  // Registering won't be same as authenticate since no password will be input
  // Only a phone number is inserted.
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const dispatchRTK = useDispatch();
  const socket = useRef();
  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        const userNum = localStorage.getItem('number');
        const response = await axios.get(`/api/user/${userNum}`);

        const user = response.data;
        if (user) {
          dispatchRTK(getUserMessages());
          const newSocket = io('http://localhost:7080');
          // const newSocket = io("https://db.lipagas.co.ke")

          newSocket.emit('connect-user', userNum);
          socket.current = newSocket;
          dispatchRTK(addSocketId(socket));

          if (socket.current) {
            socket.current.on('reply-received', (data) => {
              // console.log(data)
              dispatchRTK(getUserMessages());
            });
          }
        }
        // const user_id = JSON.parse(localStorage.getItem("user")).id;
        // try {
        //   const lpgStatus = await axios.get(`/api/lpg-status/${user_id}`);

        //   console.log("This is the status", lpgStatus.data);
        //   localStorage.setItem("lpgstatus", JSON.stringify(lpgStatus.data));
        // } catch (e) {
        //   console.log("LPG not found");
        // }
        // try {

        //   const signal = await axios.get(`/api/get-device-data`);

        //   console.log("This is the signal", signal?.data);
        //   localStorage.setItem("signal", JSON.stringify(signal?.data));
        // } catch (e) {
        //   console.log("LPG not found");
        // }

        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [storageAvailable, dispatchRTK]);

  useEffect(() => {
    initialize();
  }, [initialize, socket]);

  // LOGIN
  const login = useCallback(async (password) => {
    const formData = new URLSearchParams();
    formData.append('phoneNumber', localStorage.getItem('number'));
    formData.append('password', password);
    // Password means OTP in this sense
    const response = await axios.post('/api/login', formData, {
      withCredentials: true,
      credentials: 'include',
    });
    console.log(response.data);
    const { accessToken, user } = response.data;
    localStorage.setItem('user', JSON.stringify(user));
    setSession(accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        user, // instead of user
      },
    });
    return user;
  }, []);

  // REGISTER
  const register = useCallback(async (phoneNumber) => {
    const response = await axios.post('/api/register', {
      phoneNumber,
    });
    const { user } = response.data;
    localStorage.setItem('number', phoneNumber);
    localStorage.setItem('user', JSON.stringify(user));
    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  }, []);
  // SETUP
  const setup = useCallback(async (data) => {
    const formData = new URLSearchParams();
    console.log(data);
    localStorage.setItem('email', data.email);
    formData.append('email', data.email);
    formData.append('phoneNumber', localStorage.getItem('number'));
    formData.append('name', `${data.firstName} ${data.lastName}`);
    // Password means OTP in this sense
    const response = await axios.put('/api/updateuser', formData, {
      withCredentials: true,
      credentials: 'include',
    });
    console.log(response.data);
    const { user } = response.data;
    // setSession(accessToken);
    dispatch({
      type: 'SETUP',
      payload: {
        user, // instead of user
      },
    });
  }, []);

  // PERSIST USER

  // VERIFYEMAILCODE
  const verifyEmailCode = useCallback(async (otp) => {
    const formData = new URLSearchParams();
    const email = localStorage.getItem('email');
    formData.append('otp', otp);
    formData.append('email', email);
    // Password means OTP in this sense
    const response = await axios.post('/api/verifyEmail', formData, {
      withCredentials: true,
      credentials: 'include',
    });
    console.log(response.data);
    const { user } = response.data;
    console.log(user);
    // accessToken has been removed from above
    // setSession(accessToken);
    dispatch({
      type: 'VERIFYEMAILCODE',
      payload: {
        user, // instead of user
      },
    });
  }, []);
  // RESETOTP
  const resetOtp = useCallback(async () => {
    const phoneNumber = localStorage.getItem('number');
    const response = await axios.post('/api/resetotp', {
      phoneNumber,
    });
    // const { user } = response.data;
    // dispatch({
    //   type: 'RESETOTP',
    //   payload: {
    //     user,
    //   },
    // });
    return response.data;
  }, []);
  // RESETEMAILOTP
  const resetEmailOtp = useCallback(async () => {
    const phoneNumber = localStorage.getItem('number');
    const email = localStorage.getItem('email');
    const response = await axios.post('/api/resetemailotp', {
      phoneNumber,
      email,
    });
    return response.data;
  }, []);
  // Update Profile
  const updateProfile = useCallback(async (formData) => {
    // const response = await axios.put('/api/users/update-profile', formData);
    const response = await axios.put('/api/users/update-profile', formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Set the content type for file uploads
      },
    });
    // Handle success - you can redirect to a success page or show a success message
    console.log('User profile updated:', response.data);
    const { user } = response.data;
    dispatch({
      type: 'UPDATEPROFILE',
      payload: {
        user,
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(() => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);
  // GET EMAIL From GOOGLE
  const loginWithGoogle = useCallback(async () => {
    const form = document.createElement('form');
    form.action = '/api/auth';
    form.method = 'GET';
    form.target = '_blank';
    const input = document.createElement('input');
    input.type = 'hidden';
    form.appendChild(input);
    document.body.appendChild(form);
    form.submit();
    // Create a promise that resolves when a message is received from the server
    const responsePromise = new Promise((resolve) => {
      console.log('attempting to see if this works');
      window.addEventListener('message', (event) => {
        if (event.origin === '/api/auth/google/callback') {
          const response = event.data;
          resolve(response);
        }
      });
    });
    // Wait for the promise to resolve
    const response = await responsePromise;
    console.log('Response from server:', response);
    return true;
    // Process the response as needed
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'jwt',
      setup,
      verifyEmailCode,
      login,
      updateProfile,
      resetOtp,
      resetEmailOtp,
      loginWithGoogle,
      register,
      logout,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      setup,
      loginWithGoogle,
      updateProfile,
      verifyEmailCode,
      login,
      resetOtp,
      resetEmailOtp,
      logout,
      register,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
