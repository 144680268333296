// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  app: icon('ic_flame'),
  ecommerce: icon('ic_card'),
  analytics: icon('ic_transactions'),
  banking: icon('ic_refill'),
  booking: icon('ic_support'),
  file: icon('ic_about'),
}

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Home', path: PATH_DASHBOARD.general.app, icon: ICONS.app },
      { title: 'Top up', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
      { title: 'Transactions', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      { title: 'Refill', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      { title: 'Help and Support', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
      { title: 'About', path: PATH_DASHBOARD.general.file, icon: ICONS.file },
    ],
  },

  // // MANAGEMENT
];

export default navConfig;
