// @mui
import { Stack, Button, Typography, Box } from '@mui/material';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// locales
import { useLocales } from '../../../locales';
// routes
import {PATH_DASHBOARD } from '../../../routes/paths';

// ----------------------------------------------------------------------

export default function NavDocs() {
  const { user } = useAuthContext();

  const { translate } = useLocales();

  return (
    <Stack
      spacing={3}
      sx={{
        px: 5,
        pb: 5,
        mt: 10,
        width: 1,
        display: 'block',
        textAlign: 'center',
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={77} // Adjust the height as needed
      >
        <img
          src="/assets/illustrations/order_gas_illustration.png"
          alt="Order Gas Illustration"
          style={{ maxWidth: '100%', maxHeight: '100%' }}
        />
      </Box>

      <div>
        <Typography gutterBottom variant="subtitle1">
          {/* eslint-disable-next-line no-unsafe-optional-chaining */}
          {`${translate('docs.hi')}, ${user.name.split(" ")[0].charAt(0).toUpperCase() + user.name.split(" ")[0].slice(1)}`}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'pre-line' }}>
          {`${translate('docs.description')}`}
        </Typography>
      </div>

      <Button href={PATH_DASHBOARD.general.banking} rel="noopener" variant="contained" borderradius='50px'>
        {`${translate('docs.documentation')}`}
      </Button>
    </Stack>
  );
}
