// ----------------------------------------------------------------------

export default function Link(theme) {
  return {
    MuiLink: {
      styleOverrides: {
        root: {
          borderRadius: "25px",
        },
      },

      defaultProps: {
        underline: 'hover',
      },
    },
  };
}
