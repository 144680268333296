// @mui
import PropTypes from 'prop-types';
import {
    Tooltip,
} from '@mui/material';
import SignalCellular0BarIcon from '@mui/icons-material/SignalCellularConnectedNoInternet0Bar';
import SignalCellular1BarIcon from '@mui/icons-material/SignalCellular1Bar';
import SignalCellular2BarIcon from '@mui/icons-material/SignalCellular2Bar';
import SignalCellular4BarIcon from '@mui/icons-material/SignalCellular4Bar';
import SignalCellular3BarIcon from '@mui/icons-material/SignalCellular3Bar';
// utils
// components
import { IconButtonAnimate } from '../../../components/animate';


// ----------------------------------------------------------------------
function signalIcon(val = "1") {
    if (val >= 27) {
        return <SignalCellular4BarIcon />;
    }
    if (val >= 24) {
        return <SignalCellular3BarIcon />;
    }
    if (val >= 20) {
        return <SignalCellular2BarIcon />;
    }
    if (val >= 16) {
        return <SignalCellular1BarIcon />;
    }
    return  <SignalCellular0BarIcon />;
}
SignalStrength.propTypes = {
    signal: PropTypes.number
}
export default function SignalStrength({signal}) {
    return (
        <IconButtonAnimate
            color='default'
            sx={{ width: 40, height: 40 }}
        >
           
           <Tooltip title={signal ? `${signal} signal` : 'No signal'}>
                {signalIcon(signal)}
            </Tooltip>
        </IconButtonAnimate>
    );
}

