import { Navigate, useRoutes } from 'react-router-dom';

// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
// import MainLayout from '../layouts/main';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  VerifyEmailCodePage,
  RegisterUserDataPage,
  // NewPasswordPage,
  // ResetPasswordPage,
  // Dashboard: General
  GeneralAppPage,
  GeneralFilePage,
  GeneralBankingPage,
  GeneralBookingPage,
  GeneralEcommercePage,
  GeneralAnalyticsPage,
  // Dashboard: User
  // UserListPage,
  // UserEditPage,
  // UserCardsPage,
  // UserCreatePage,
  UserAccountPage,
  // Dashboard: App
  //
  // BlankPage,
  // PermissionDeniedPage,
  //
  // Page500,
  // Page403,
  Page404,
  HomePage,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
 
  return useRoutes([
    {
      path: '/',
      // element: <Navigate to={PATH_AFTER_LOGIN} replace />,
      element: <HomePage/>
    },
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              {/* <RegisterPage /> */}
              <HomePage/>
            </GuestGuard>
          ),
        },
        { path: 'setup-profile', element: <RegisterUserDataPage /> },
        { path: 'login-unprotected', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            // { path: 'reset-password', element: <ResetPasswordPage /> },
            // { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
            { path: 'verify-email', element: <VerifyEmailCodePage /> },
          ],
        },
      ],
    },

    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'home', element: <GeneralAppPage /> },
        { path: 'top-up', element: <GeneralEcommercePage /> },
        { path: 'transactions', element: <GeneralAnalyticsPage /> },
        { path: 'refill', element: <GeneralBankingPage /> },
        { path: 'customer-care', element: <GeneralBookingPage /> },
        { path: 'about', element: <GeneralFilePage /> },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            // { path: 'cards', element: <UserCardsPage /> },
            // { path: 'list', element: <UserListPage /> },
            // { path: 'new', element: <UserCreatePage /> },
            // { path: ':name/edit', element: <UserEditPage /> },
            { path: 'account', element: <UserAccountPage /> },
          ],
        },
        // { path: 'permission-denied', element: <PermissionDeniedPage /> },
        // { path: 'blank', element: <BlankPage /> },
      ],
    },

    // Main Routes
    // {
    //   element: <MainLayout />,
    //   children: [
    //     { element: <HomePage />, index: true },
    //     // Demo Components
    //   ],
    // },
    {
      element: <CompactLayout />,
      children: [
        // { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        // { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
