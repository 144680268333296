import PropTypes from 'prop-types';
import { useState, useEffect } from 'react'
// @mui
import { useTheme } from '@mui/material/styles';
import { Stack, AppBar, Toolbar, IconButton } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// config
import { HEADER, NAV } from '../../../config-global';
// components
import Logo from '../../../components/logo';
import Iconify from '../../../components/iconify';
import { useSettingsContext } from '../../../components/settings';
//
import AccountPopover from './AccountPopover';
// import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';
import BatteryStatus from './BatteryStatus';
import SignalStrength from "./SignalStrength";
import OffButton from "./OffButton"
import axios from "../../../utils/axios"
// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const theme = useTheme();

  const { themeLayout } = useSettingsContext();

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const isDesktop = useResponsive('up', 'lg');

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

  const [signal, setSignal] = useState()
  const [battery, setBattery] = useState()
  // const [Error, setError] = useState()
  const renderContent = (
    <>
      {isDesktop && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!isDesktop && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1, color: 'text.primary' }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )}
      {/* {Error && <Alert severity="error">{Error}</Alert>} */}
      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1.5 }}
      >
        <OffButton />
        <SignalStrength signal={signal} />
        <BatteryStatus battery={battery} />
        <NotificationsPopover />
        <AccountPopover />
      </Stack>
    </>

  );
  const fetchBatteryData = async () => {
    try {
      const user_id = JSON.parse(localStorage.getItem("user")).id;
      console.log(user_id);
      const lpgStatus = await axios.get(`/api/lpg-status/${user_id}`);
      console.log("In the header", lpgStatus.data.battery);
      setBattery(Number(lpgStatus?.data?.battery))
    } catch (e) {
      console.error("Battery Data",e.errmsg);
      // setError(e.errmsg)
    }

  }
  const fetchSignalData = async () => {
    try {
      const sig = await axios.get(`/api/get-device-data`);
      console.log("This is the signal", sig);
      setSignal(sig?.data);
    } catch (e) {
      console.error("This is the second error ", e);
      // setError(e?.errmsg)
    }
  }
  useEffect(() => {
    fetchBatteryData();
    fetchSignalData();
  }, [])
  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
