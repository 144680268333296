import { useState, useEffect } from 'react';
// @mui
import {
    Switch,
    FormControl,
    FormControlLabel,
    // Tooltip,
} from '@mui/material';
// utils
import { useSnackbar } from "../../../components/snackbar";
import axios from "../../../utils/axios";
// components
import { IconButtonAnimate } from '../../../components/animate';


// ----------------------------------------------------------------------

export default function OffButton() {
    const [state, setState] = useState(false);
    // const [state, setState] = useState(Boolean(JSON.parse(localStorage.getItem("lpgstatus")).valveState)); // To be tested

    useEffect(() => {
        setState(Boolean(Number(JSON.parse(localStorage.getItem("lpgstatus"))?.valveState)))
    }, []);
    const { enqueueSnackbar } = useSnackbar();

    const handleClick = async () => {
        setState((prevState) => !prevState);
        // alert(`Valve turned ${!state ? 'ON' : 'OFF'}`);
        // When the switch is checked, its state appears to be false
        const newStatus = !state ? "0" : "1";
        const formData = new URLSearchParams();
        formData.append('user_id', "33");
        formData.append('status', newStatus);
        // Password means OTP in this sense
        const response = await axios.post('/api/toggle-valve', formData, {
            withCredentials: true,
            credentials: 'include'
        });
        console.log(response.data);
        enqueueSnackbar(`Valve turned ${!state ? 'ON' : 'OFF'}`)
    }
    return (
        <IconButtonAnimate
            color='default'
            sx={{ width: 40, height: 40, marginRight: 4 }}
        >
            <FormControl>
                <FormControlLabel
                    control={
                        <Switch
                            checked={state}
                            onChange={handleClick}
                            aria-label='Turn on Valve' />
                    }
                    label="ON"
                    labelPlacement="end"
                />
            </FormControl>

        </IconButtonAnimate>
    );
}